.main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: background 0.3s linear;
}
.main-content > .container {
  transition: height 0.3s linear;
  /* height: 625px; */
}
.root-cards-revealed .main-content {
  background: url(/img/jr-korpa-jD1huE0iPk0-unsplash.jpg) no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


.title-wrapper {
  position: relative;
  height: 200px;
  transition: height 0.3s linear;
}
.title {
  position: absolute;
  left: 0; right: 0;
  opacity: 1;
  transition: opacity 0.3s linear;
  margin: 0;
}
.title-result {
  position: absolute;
  left: 0; right: 0;
  top: 0; right: 0;
  opacity: 0;
  transition: opacity 0.3s 1s linear;
  margin: 0;
}
.title-result h2 {
  text-transform: uppercase;
  color: #c1d9ef;
  font-weight: 300;
}
.root-cards-revealed .title-wrapper {
  height: 100px;
}

.hide-on-reveal  {
  transition: opacity 0.3s linear;
}

.cards-wrapper {
  position: relative;
  height: 250px;
}
.card {
  /* position: relative; */
  cursor: pointer;
  position: absolute;
  width: 121px;
  height: 200px;
  perspective: 1000px;
  transition: all 0.3s ease-in-out 0s;
}

.card .card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transform-style: preserve-3d;
}
.card .card-flip-front,
.card .card-flip-back {
  position: absolute;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.card .card-flip-front {
  transform: rotateX(0deg);
}
.card .card-flip-back {
  transform: rotateY(180deg) rotateX(0deg);
}
.card .card-flip-back.backward {
  transform: rotateY(180deg) scaleY(-1);
}

.card .card-title {
  font-family: inherit;
  font-weight: 500;
  line-height: 22px;
  color: inherit;
  font-size: 16px;
}

.card:nth-child( 1 ) { z-index: 12 ; }
.card:nth-child( 2 ) { z-index: 11 ; }
.card:nth-child( 3 ) { z-index: 10 ; }
.card:nth-child( 4 ) { z-index: 9 ; }
.card:nth-child( 5 ) { z-index: 8 ; }
.card:nth-child( 6 ) { z-index: 7 ; }
.card:nth-child( 7 ) { z-index: 6 ; }
.card:nth-child( 8 ) { z-index: 5 ; }
.card:nth-child( 9 ) { z-index: 4 ; }
.card:nth-child( 10 ) { z-index: 3 ; }
.card:nth-child( 11 ) { z-index: 2 ; }
.card:nth-child( 12 ) { z-index: 1 ; }

.card:hover {
  animation-name: go-up;
  animation-duration: 0.1s;
  animation-fill-mode: both;
}

@keyframes go-up {
  from {transform: translate(0, 0);}
  to {transform: translate(0, -20px);}
}

.card-selected:hover {
  animation: none;
}
.card-selected {
  transform: translate(0, -20px);
}

.cards-reveal .card {
  pointer-events: none;
}

.cards-reveal .card-selected {
  animation-name: feel-the-power;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@keyframes feel-the-power {
  0% {transform: scale(1) translate(0, -20px); box-shadow: none;}
  50% {transform: scale(1.02) translate(0, -22px); box-shadow: 0px 0px 20px 1px rgba(255, 255, 255, 0.28)}
  100% {transform: scale(1) translate(0, -20px); box-shadow: none;}
}

.cards-revealed .card:not(.card-selected) {
  animation-name: make-room;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
.no-anim .cards-revealed .card:not(.card-selected) {
  animation-duration: 0s;
}

@keyframes make-room {
  to {
    transform: translate(0, 50px);
    opacity: 0;
  }
}

.root-cards-revealed .hide-on-reveal {
  opacity: 0;
  height: 0;
  pointer-events: none;
}
.root-cards-revealed .main-content > .container {
  /* min-height: 1100px; */
}

.cards-revealed .cards-row {
  pointer-events: none;
}

.cards-revealed .card.card-selected {
  animation-duration: 0.5s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  animation-iteration-count: initial;
}
.cards-revealed .card.card-selected .card-inner {
  animation-name: flip;
  animation-duration: 0.3s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: initial;
}
.cards-revealed .card.card-selected-1 {
  animation-name: fill-room-1;
}
.cards-revealed .card.card-selected-2 {
  animation-name: fill-room-2;
}
.cards-revealed .card.card-selected-3 {
  animation-name: fill-room-3;
}
.no-anim .cards-revealed .card.card-selected {
  animation-duration: 0s;
  animation-delay: 0s;
}
.no-anim .cards-revealed .card.card-selected .card-inner {
  animation-delay: 0.2s;
}

@keyframes fill-room-1 {
  to {
    transform: scale(1.2);
    top: 5%;
    left: 31%;
  }
}
@keyframes fill-room-2 {
  to {
    transform: scale(1.2);
    top: 5%;
    left: 45%;
  }
}
@keyframes fill-room-3 {
  to {
    transform: scale(1.2);
    top: 5%;
    left: 59%;
  }
}

@keyframes flip {
  to {
    transform: rotateY(180deg);
  }
}

.cards-revealed .card.card-selected .card-title {
  opacity: 1;
}

.ghost-cards {
  opacity: 0;
  pointer-events: none;
  position: relative;
  height: 250px;
  transition: opacity 0.3s 1s linear;
}
.cards-revealed .ghost-cards{
  opacity: 1;
}
.cards-revealed .ghost-cards .card {
  top: 15%;
  transform: scale(1.2) !important;
  padding: 7px;
}
.ghost-cards .card {
  left: 45% !important;
  transition: left 1s 0.5s ease-in-out !important;
  z-index: 0;
}
.cards-revealed .ghost-cards .card:nth-child( 1 ) { left: calc(31% - (120px * 1) - (30px * 1)) !important; }
.cards-revealed .ghost-cards .card:nth-child( 2 ) { left: calc(31% - (120px * 2) - (30px * 2)) !important; }
.cards-revealed .ghost-cards .card:nth-child( 3 ) { left: calc(31% - (120px * 3) - (30px * 3)) !important; }
.cards-revealed .ghost-cards .card:nth-child( 4 ) { left: calc(59% + (120px * 1) + (30px * 1)) !important; }
.cards-revealed .ghost-cards .card:nth-child( 5 ) { left: calc(59% + (120px * 2) + (30px * 2)) !important; }
.cards-revealed .ghost-cards .card:nth-child( 6 ) { left: calc(59% + (120px * 3) + (30px * 3)) !important; }


.analiza-wrapper {
  z-index: 0;
  pointer-events: none;
  margin-top: 30px;
  opacity: 0;
  position: absolute;
  transition: opacity 0.3s 1s linear;
}
.root-cards-revealed .analiza-wrapper {
  position: initial;
  opacity: 1;
  pointer-events: initial;
}

.note {
  transition: opacity 0.3s 1s linear;
  opacity: 0;
}
.note p {
  color: grey;
  font-size: 12px;
  line-height: 11px;
}
.root-cards-revealed .note {
  opacity: 1;
}

@media (max-width:1023px) {
  .navbar-header {
    display: flex;
  }
  .navbar-brand {
    margin: auto;
  }
  .main-content > .container {
    width: auto !important;
    /* height: 720px; */
  }
  .root-cards-revealed .main-content > .container {
    height: auto;
    min-height: auto;
  }
  .title-wrapper {
    height: 195px;
    position: initial;
  }
  .title-wrapper .title {
    position: initial;
  }
  .title-wrapper .title-result {
    position: absolute;
  }
  .title-wrapper .title h1 {
    font-size: 28px;
  }
  .title-wrapper .title h4 {
    font-size: 18px;
  }
  .title-wrapper .title h5 {
    font-size: 18px;
  }
  .title-wrapper .title-result h2 {
    font-size: 28px;
  }
  .root-cards-revealed .title-wrapper {
    height: initial;
  }
  .root-cards-revealed .title-wrapper .title {
    position: absolute;
  }
  .root-cards-revealed .title-wrapper .title-result {
    position: initial;
  }
  .btn-tarot {
    font-size: 16px;
    margin: 16px 0;
  }

  h6 {
    font-size: 15px;
  }

  .cards-row:nth-child(2) {
    top: 41.5% !important;
    left: calc(100vw / 2 - (121px + (121px / 2)))  !important;
  }
  .cards-row:nth-child(2) {
    top: 41.5% !important;
    left: calc(100vw / 2 - (121px / 2))  !important;
  }
  .cards-row:nth-child(2) {
    top: 41.5% !important;
    left: calc(100vw / 2 + (121px / 2))  !important;
  }

  .cards-wrapper.cards-revealed {
    position: absolute;
    width: 91%;
  }
  .cards-revealed .card.card-selected-1 {
    animation-name: fill-room-1-mobile;
  }
  .cards-revealed .card.card-selected-2 {
    animation-name: fill-room-2-mobile;
  }
  .cards-revealed .card.card-selected-3 {
    animation-name: fill-room-3-mobile;
  }

  .analiza-wrapper {
    padding: 0;
    margin-top: 0px;
    position: absolute;
    bottom: 0;
    pointer-events: none;
  }
  .root-cards-revealed .analiza-wrapper {
    position: initial;
  }

  .analiza-wrapper > .container > div {
    line-height: 1;
    padding: 0;
  }

  .root-cards-revealed .note {
    margin-top: 260px !important;
  }
  .note {
    padding: 0;
  }
}

@keyframes fill-room-1-mobile {
  to {
    transform: scale(0.8);
    top: 100%;
    left: calc(92vw / 2 - (121px + (121px / 2)));
  }
}
@keyframes fill-room-2-mobile {
  to {
    transform: scale(0.8);
    top: 100%;
    left: calc(92vw / 2 - (121px / 2));
  }
}
@keyframes fill-room-3-mobile {
  to {
    transform: scale(0.8);
    top: 100%;
    left: calc(92vw / 2 + (121px / 2));
  }
}